<template>
  <v-menu
    v-model="showCalendar"
    transition="slide-y-transition"
    :close-on-content-click="false"
    offset-y>

    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="datesLabel"
        label="Data"
        append-icon="mdi-calendar"
        :hide-details="true"
        v-bind="attrs"
        v-on="on"
        readonly
        outlined
        :dense="dense">
      </v-text-field>
    </template>

    <v-date-picker
      v-model="dates"
      :color="variables.colorPrimary"
      :close-on-content-click="false"
      no-title
      scrollable
      range>

      <v-spacer/>
      <v-btn text color="primary" @click="showCalendar = false">Cancel</v-btn>
      <v-btn text color="primary" @click="showCalendar = false">OK</v-btn>
    </v-date-picker>
  </v-menu>
</template>

<script>
import variables from '@/assets/styles/helpers/_variables.scss'
import moment from 'moment'
import { map } from 'lodash'

export default {
  name: 'OrganizzeCalendar',
  props: {
    getDates: {
      type: Function,
      required: true
    },

    dense: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  directives: {},
  components: {},
  data: () => ({
    showCalendar: false,
    datesLabel: [moment().format('DD/MM/YYYY')],
    sortedDates: [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
    dates: [moment().format('YYYY-MM-DD')]
  }),
  created () {},
  mounted () {
    const self = this
    self.getDates(self.createResponse(self.sortedDates))
  },
  computed: { variables: () => variables },
  watch: {
    dates () {
      const self = this
      self.sortedDates = map(map(self.dates, date => moment(date).valueOf()).sort(), time => moment(time).format('YYYY-MM-DD'))
      self.datesLabel = map(self.sortedDates, date => moment(date).format('DD/MM/YYYY')).join(' ~ ')
      self.getDates(self.createResponse(self.sortedDates))
    }
  },
  methods: {
    createResponse (val) {
      return { startDate: val[0], endDate: val[1] || val[0] }
    },
    reset () {
      const self = this
      self.dates = [moment().format('YYYY-MM-DD')]
    }
  },
  validations () { return {} }
}
</script>